/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@googlemaps/js-api-loader';

import { AddressWrap, fieldStyles } from './contactFormStyles';
import { XIcon } from '../ui/icons';

let googleApiClient;

async function getGoogleMapsApiClient() {
  if (googleApiClient) {
    return googleApiClient;
  }
  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_GEOCODING_API_KEY_TWO || '',
    version: 'weekly',
    libraries: ['places'],
  });
  googleApiClient = await loader.load();
  return googleApiClient;
}

function AddressAutocomplete({ setAddress, onNoResultsFound, label, css }) {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const sessionTokenRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    getGoogleMapsApiClient();
  }, []);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (!newValue || newValue.trim().length <= 1) {
      setSuggestions([]);
      return;
    }

    timeoutRef.current = setTimeout(async () => {
      const google = await getGoogleMapsApiClient();

      if (!sessionTokenRef.current) {
        sessionTokenRef.current = new google.maps.places.AutocompleteSessionToken();
      }

      new google.maps.places.AutocompleteService().getPlacePredictions(
        {
          input: newValue,
          sessionToken: sessionTokenRef.current,
          componentRestrictions: { country: 'US' },
          types: ['address'],
        },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
            setSuggestions([]);
            onNoResultsFound();
            return;
          }
          if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
            onNoResultsFound();
            return;
          }
          setSuggestions(predictions);
        },
      );
    }, 350);
  };

  const handleSuggestionSelected = async (suggestion) => {
    setSuggestions([]);
    const google = await getGoogleMapsApiClient();

    const sessionToken = sessionTokenRef.current;
    sessionTokenRef.current = null;

    new google.maps.places.PlacesService(
      document.getElementById('googlemaps-attribution-container'),
    ).getDetails(
      {
        placeId: suggestion.place_id,
        fields: ['address_components', 'name'],
        sessionToken,
      },
      (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          setValue(place.name);

          let address = {
            addressLine1: '',
            city: '',
            state: '',
            zip: '',
          };

          for (const component of place.address_components) {
            const componentType = component.types[0];

            switch (componentType) {
              case 'street_number': {
                address.addressLine1 = `${component.long_name}`;
                break;
              }
              case 'route': {
                address.addressLine1 += ` ${component.short_name}`;
                break;
              }
              case 'postal_code': {
                address.zip = `${component.long_name}`;
                break;
              }
              case 'locality':
              case 'sublocality_level_1': {
                address.city = component.long_name;
                break;
              }
              case 'administrative_area_level_1': {
                address.state = component.short_name;
                break;
              }
              default:
                break;
            }
          }

          // Check if addressLine1 has both street number and route
          if (!address.addressLine1.trim()) {
            address.addressLine1 = place.name;
          }

          setAddress(address);
        }
      },
    );
  };

  return (
    <AddressWrap>
      <input
        placeholder={label}
        onChange={handleChange}
        value={value}
        name='addressLine1'
        css={[fieldStyles, css]}
      />
      {suggestions.length > 0 && (
        <div className='dropdown_wrap'>
          <ul role='listbox'>
            <XIcon onClick={() => setSuggestions([])} />
            {suggestions.map((suggestion) => (
              <li
                key={suggestion.place_id}
                tabIndex={0}
                role='option'
                aria-selected='false'
                onClick={() => handleSuggestionSelected(suggestion)}
              >
                {suggestion.description}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div id='googlemaps-attribution-container'></div>
    </AddressWrap>
  );
}

AddressAutocomplete.propTypes = {
  setAddress: PropTypes.func.isRequired,
  onNoResultsFound: PropTypes.func.isRequired,
  label: PropTypes.string,
  css: PropTypes.object,
};

AddressAutocomplete.defaultProps = {
  label: 'Address',
  css: {},
};

export default AddressAutocomplete;
