import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';

import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import MobileFooter from '../components/layout/MobileFooter';
import MobileHeader from '../components/layout/MobileHeader';

const Root = styled.main`
  overflow: visible;
  display: flex;
  justify-content: center;
  padding: 40px 20px;
  @media(min-width: 1024px) {
    padding-top: 60px;
  }
`;

export function One() {
  return (
    <Fragment>
      <header styles={{ position: 'relative', zIndex: '400' }}>
        <MobileHeader />
        <Header />
      </header>
      <Root
        style={{
          overflow: 'visible',
          display: 'flex',
          justifyContent: 'center',
          padding: '40px 20px',
        }}
      >
        <Helmet>
          <script src='https://www.cognitoforms.com/f/iframe.js'></script>
        </Helmet>
        <div
          style={{
            height: '6000px',
            width: '100%',
            overflow: 'visible',
            position: 'relative',
            maxWidth: '840px',
          }}
        >
          <iframe
            style={{
              width: '100%',
              overflow: 'visible',
              height: '100%',
            }}
            seamless='seamless'
            height={window.innerWidth < 1023 ? '1710' : '1582'}
            sandbox='allow-scripts'
            width='100%'
            title='feasibility analysis form'
            src='https://www.cognitoforms.com/f/v42N1cV6P0m9eqf7Qw0Skg/10'
          ></iframe>
        </div>
      </Root>
      <footer>
        <MobileFooter />
        <Footer />
      </footer>
    </Fragment>
  );
}

export function Two() {
  return (
    <Fragment>
      <header styles={{ position: 'relative', zIndex: '400' }}>
        <MobileHeader />
        <Header />
      </header>
      <Root
        style={{
          overflow: 'visible',
          display: 'flex',
          justifyContent: 'center',
          padding: '40px 20px',
        }}
      >
        <Helmet>
          <script src='https://www.cognitoforms.com/f/iframe.js'></script>
        </Helmet>
        <div
          style={{
            height: '6000px',
            width: '100%',
            overflow: 'visible',
            position: 'relative',
            maxWidth: '840px',
          }}
        >
          <iframe
            style={{
              width: '100%',
              overflow: 'visible',
              height: '100%',
            }}
            seamless='seamless'
            height={window.innerWidth < 1023 ? '1710' : '1582'}
            sandbox='allow-scripts'
            width='100%'
            title='feasibility analysis form'
            src='https://www.cognitoforms.com/f/v42N1cV6P0m9eqf7Qw0Skg/12'
          ></iframe>
        </div>
      </Root>
      <footer>
        <MobileFooter />
        <Footer />
      </footer>
    </Fragment>
  );
}

export function Three() {
  return (
    <Fragment>
      <header styles={{ position: 'relative', zIndex: '400' }}>
        <MobileHeader />
        <Header />
      </header>
      <Root
        style={{
          overflow: 'visible',
          display: 'flex',
          justifyContent: 'center',
          padding: '40px 20px',
        }}
      >
        <Helmet>
          <script src='https://www.cognitoforms.com/f/iframe.js'></script>
        </Helmet>
        <div
          style={{
            height: '6000px',
            width: '100%',
            overflow: 'visible',
            position: 'relative',
            maxWidth: '840px',
          }}
        >
          <iframe
            style={{
              width: '100%',
              overflow: 'visible',
              height: '100%',
            }}
            seamless='seamless'
            height={window.innerWidth < 1023 ? '1710' : '1582'}
            sandbox='allow-scripts'
            width='100%'
            title='feasibility analysis form'
            src='https://www.cognitoforms.com/MadisonCres/GetAComplimentaryCostSegEstimate'
          ></iframe>
        </div>
      </Root>
      <footer>
        <MobileFooter />
        <Footer />
      </footer>
    </Fragment>
  );
}
