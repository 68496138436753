/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Component } from 'react';
import { Events, scroller } from 'react-scroll';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';

import TopSection from '../components/faq/TopSection';
import TopicNavigationMobile from '../components/faq/TopicNavigationMobile';
import TopicNavigation from '../components/faq/TopicNavigation';
import TopicBottomSection from '../components/faq/TopicBottomSection';
import LastSection from '../components/home/LastSection';

export default class Faq extends Component {
  componentDidMount() {
    Events.scrollEvent.register('begin');
    Events.scrollEvent.register('end');
  }
  onSectionClick(section) {
    scroller.scrollTo(section, {
      duration: 1700,
      delay: 0,
      smooth: 'easeInOutQuad',
    });
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Madison SPECS | FAQ</title>
          <meta name='description' content='All Your Cost Segregation Questions Answered Here' />
        </Helmet>
        <div style={{ position: 'absolute', left: '-8000px', opacity: 0 }}>
          <NavLink to='/feasibility-analysis'>plain</NavLink>
          <NavLink to='/feasibility-analysis/yonah'>yonah</NavLink>
          <NavLink to='/feasibility-analysis/brian'>brian</NavLink>
          <NavLink to='/faqs-pdf'>faqs</NavLink>
        </div>
        <div css={{ position: 'relative' }}>
          <TopSection />
          <TopicNavigationMobile />
          <TopicNavigation onSectionClick={this.onSectionClick} />
        </div>
        <TopicBottomSection onSectionClick={this.onSectionClick} />
        <div css={{ '@media(min-width: 1024px)': { marginTop: 80 } }}>
          <LastSection />
        </div>
      </div>
    );
  }
}
