/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';
import axios from 'axios';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import {
  wrapper,
  par,
  fieldStyles,
  buttonS,
  buttonArrow,
  motifStyles,
  header,
  formStyles,
  textArea,
  cardStyles,
  dropdown,
  addressfield,
  multiColumn,
  fieldStylesThree,
} from './contactFormStyles';
import { RightArrow, Motif } from '../ui/icons';
import { Testimonial } from '../../pages/ContactUs';
import Spinner from '../ui/Spinner';
import { getTrackingStringForEmail } from '../../seo-tracking';
import { isValidEmail } from '../../utils';
import { findPerson, nameFinder } from '../ui/salePeeps';

import AddressAutocomplete from './AddressAutocomplete';

const defaultForm = {
  name: '',
  email: '',
  phone: '',
  addressLine1: '',
  city: '',
  state: '',
  zip: '',
  comments: '',
  hearAboutUs: '',
};

const isSubdomain = nameFinder() ? `(from ${findPerson().domain}.madisonspecs.com)` : '';

export function ContactForm() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [form, setForm] = useState(defaultForm);
  const [currentlySubmitting, setCurrentlySubmitting] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [addressError, setAddressError] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const setAddress = (address) => {
    setForm((prevForm) => ({
      ...prevForm,
      addressLine1: address.addressLine1,
      city: address.city,
      state: address.state,
      zip: address.zip,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;

    // Validation
    if (!form.name) {
      setNameError(true);
      isValid = false;
    }
    if (!isValidEmail(form.email)) {
      setEmailError(true);
      isValid = false;
    }
    if (!form.phone) {
      setPhoneError(true);
      isValid = false;
    }
    if (!form.addressLine1 || !form.city || !form.state || !form.zip) {
      setAddressError(true);
      isValid = false;
    }
    if (!isValid) {
      return null;
    }

    const propertyAddress = [form.addressLine1, form.city, form.state, form.zip]
      .filter(Boolean)
      .join(', ');

    setCurrentlySubmitting(true);

    var token = await executeRecaptcha('contact_form');
    console.log(token);
    var data = {
      ...form,
      propertyAddress, // Use concatenated address
      tracking: getTrackingStringForEmail(),
      subdomain: isSubdomain,
      recaptchaToken: token,
    };

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    if (process.env.NODE_ENV === 'development') {
      console.log('submitting form', data);
    } else {
      try {
        const responses = await Promise.all([
          axios.post(
            'https://specscontactform.azurewebsites.net:443/api/ContactForm/triggers/manual/invoke?api-version=2020-05-01-preview&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=RzTrXBgTWR4cSdG5mtQ1cSejKORP1a4nudZPiljQfcA',
            data,
          ),
          axios.post(
            'https://prod-11.westus.logic.azure.com/workflows/9b6da034783043da9c419c5e251ec892/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=zKM8thO1Lu_NskJ6gj-et4tR2Gh08MYK-Nqiw-6DyJc',
            data,
          ),
          axios.post(
            'https://prod-47.westus.logic.azure.com/workflows/c511f79834cc41599492ac0ec2b28029/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=-FCr8k0i0Ici3M_8tsNGA9XZelD2RcPVsi05jlp723s',
            data,
          ),
        ]);

        responses.forEach((response) => {
          if (
            response.data.message ===
            'Recaptcha score indicates this request likely came from a bot. Form not processed.'
          ) {
            console.log('Bot detected, not submitting form');
            console.log('score: ', response.data.score);
          }
          if (response.data.message === 'Error verifying recaptcha token. Form not processed') {
            console.log('Error verifying recaptcha token, not submitting form');
            console.log('errorCodes: ', response.data.errorCodes);
          }
        });
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }

    setFormSubmitted(true);
    setForm(defaultForm);
    setCurrentlySubmitting(false);
    setTimeout(() => setFormSubmitted(false), 6000);
  };

  return (
    <div css={wrapper}>
      <div css={motifStyles}>
        <Motif />
      </div>
      {currentlySubmitting && <Spinner />}
      <div
        css={
          formSubmitted && {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }
        }
      >
        <div>
          <h1 className='syne' css={header}>
            Contact Us
          </h1>
          <p css={par}>
            Our team is happy to answer all your Cost Segregation questions. Fill out the form and
            we will be in touch with you as soon as possible.
          </p>
        </div>
        {formSubmitted ? (
          <div
            css={{
              lineHeight: 1.4,
              fontSize: 24,
              marginBottom: 16,
              position: 'relative',
              zIndex: 60,
            }}
          >
            <div className='form-submitted'>
              Thank you for contacting Madison SPECS.
              <br />
              We will get back to you shortly.
            </div>
          </div>
        ) : (
          <div className='relative'>
            <form data-netlify='true' name='contact'>
              <div css={formStyles}>
                <input
                  onChange={onChange}
                  type='text'
                  name='name'
                  required
                  css={[
                    fieldStyles,
                    nameError && { border: '1px solid red' },
                    { '@media(min-width: 768px)': { marginRight: 24 } },
                  ]}
                  placeholder='* Name'
                />
                <input
                  type='email'
                  name='email'
                  css={[fieldStyles, emailError && { border: '1px solid red' }]}
                  placeholder='* Email'
                  onChange={onChange}
                />
              </div>
              <div css={formStyles}>
                <input
                  onChange={onChange}
                  type='tel'
                  name='phone'
                  css={[
                    fieldStyles,
                    phoneError && { border: '1px solid red' },
                    { '@media(min-width: 768px)': { marginRight: 24 } },
                  ]}
                  placeholder='* Phone'
                />
                <select
                  name='hearAboutUs'
                  css={[fieldStyles, dropdown]}
                  onChange={onChange}
                  value={form.hearAboutUs}
                >
                  <option>How did you hear about Madison Specs</option>
                  <option value='internet-search'>Internet search</option>
                  <option value='business-friend'>Business partner or friend</option>
                  <option value='yonah-weiss'>Yonah Weiss/Weiss Advice</option>
                  <option value='online-ad'>Online ad</option>
                  <option value='social-media'>Social media</option>
                  <option value='other'>Other</option>
                </select>
              </div>
              <div>
                <AddressAutocomplete
                  setAddress={setAddress}
                  onNoResultsFound={() => setAddressError(true)}
                  label='* Address'
                  css={[fieldStyles, addressfield, addressError && { border: '1px solid red' }]}
                />
                <div css={multiColumn}>
                  <input
                    type='text'
                    name='city'
                    value={form.city}
                    onChange={onChange}
                    css={[fieldStylesThree, addressError && { border: '1px solid red' }]}
                    placeholder='* City'
                  />
                  <input
                    type='text'
                    name='state'
                    value={form.state}
                    onChange={onChange}
                    css={[fieldStylesThree, addressError && { border: '1px solid red' }]}
                    placeholder='* State'
                  />
                  <input
                    type='text'
                    name='zip'
                    value={form.zip}
                    onChange={onChange}
                    css={[fieldStylesThree, addressError && { border: '1px solid red' }]}
                    placeholder='* Zip Code'
                  />
                </div>
              </div>
              <textarea
                name='comments'
                css={[fieldStyles, textArea]}
                placeholder='How can we help you?'
                onChange={onChange}
              />
              <button
                css={buttonS}
                onClick={handleSubmit}
                text='Submit'
                type='submit'
                className='submit-gtm'
              >
                <span css={{ marginLeft: 24 }}>Send a Message</span>
                <span css={buttonArrow}>
                  <RightArrow />
                </span>
              </button>
            </form>
          </div>
        )}
      </div>
      <div css={cardStyles}>
        <Testimonial />
      </div>
    </div>
  );
}

export function ReCaptchaContactForm() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey='6Ld8xuAUAAAAAMh7MgmfE_AqEnL1eDVVBZ8XvRGF'>
      <ContactForm />
    </GoogleReCaptchaProvider>
  );
}
