import styled from '@emotion/styled';

export const MilkyWayWrap = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 98px 25px;
  position: relative;
  z-index: 25;
  .milkywayImg {
    display: none;
    position: absolute;
    z-index: 1;
  }
  .astroidBelt {
    display: block;
    max-width: 366px;
    position: absolute;
    right: -76px;
    z-index: 26;
  }
  .innerPlanetaryRings {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 102px;
    justify-content: center;
    position: relative;
    z-index: 27;
  }
  @media (min-width: 1440px) {
    height: 1280px;
    gap: 114px;
    flex-direction: row;
    .milkywayImg {
      display: block;
    }
    .innerPlanetaryRings {
      gap: 114px;
      flex-direction: row;
    }
    .astroidBelt {
      display: none;
    }
  }
`;

export const Planet = styled.div`
  align-items: center;
  background-color: #0318c6;
  border-radius: 50%;
  box-shadow: 0px 0px 32px rgba(3, 24, 198, 0.2);
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  height: 247px;
  justify-content: center;
  padding: 10px;
  position: relative;
  text-align: center;
  transition: all 0.15s ease;
  vertical-align: top;
  width: 247px;
  z-index: 28;
  :before {
    content: '';
    position: absolute;
    top: -30px;
    left: -30px;
    width: calc(100% + 60px);
    height: calc(100% + 60px);
    border-radius: 50%;
    border: 1px solid #dcdcdc;
    z-index: -0;
    cursor: pointer;
  }
  .planet-quantity {
    font-size: 107px;
    font-family: Syne;
    transition: all 0.15s ease;
    line-height: 1;
    margin-top: -20px;
  }
  .planet-title {
    max-width: 150px;
    font-size: 24px;
    font-weight: 500;
    text-transform: capitalize;
    transition: all 0.15s ease;
    margin-top: 8px;
    text-align: center;
    height: 33px;
  }
  :hover {
    transform: ${(props) => (props.isExpanded ? 'scale(1)' : 'scale(1.07)')};
  }
  @media (min-width: 1440px) {
    flex: 0 0 auto;
    width: 346px;
    height: 346px;
    ::before {
      top: -36px;
      left: -36px;
      width: calc(100% + 72px);
      height: calc(100% + 72px);
    }
    .planet-quantity {
      font-size: 111px;
    }
    .planet-title {
      font-size: 31px;
      height: 70px;
      max-width: 250px;
    }
  }
`;
